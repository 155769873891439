var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-xl-3 col-lg-4 col-md-5 col-12"
  }, [_c('h4', [_vm._v("Sections")]), _c('VerticalPills', {
    attrs: {
      "items": _vm.categories,
      "item-key": "id"
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('a', {
          staticClass: "nav-link",
          class: {
            active: _vm.selectedCategory.id === item.id
          },
          attrs: {
            "href": "#"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.onSelectCategory(item);
            }
          }
        }, [_c('div', {
          staticClass: "mb-0"
        }, [_c('i', {
          staticClass: "fa fa-fw mr-1 text-primary",
          class: "".concat(item.icon)
        }), _vm._v(" " + _vm._s(item.title))])])];
      }
    }])
  })], 1), _c('div', {
    staticClass: "col"
  }, [_c('SectionTitle', {
    attrs: {
      "icon": _vm.selectedCategory.icon
    }
  }, [_vm._v(_vm._s(_vm.selectedCategory.title) + " Tools")]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    class: _vm.selectedCategory.class
  }, [_c('router-view')], 1)])], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }