<template>
  <div class="row">
    <div class="col-xl-3 col-lg-4 col-md-5 col-12">
      <h4>Sections</h4>
      <VerticalPills :items="categories" item-key="id">
        <template #item="{ item }">
          <a href="#" class="nav-link" :class="{ active: selectedCategory.id === item.id }" @click.prevent="onSelectCategory(item)">
            <div class="mb-0"><i class="fa fa-fw mr-1 text-primary" :class="`${item.icon}`"></i> {{ item.title }}</div>
          </a>
        </template>
      </VerticalPills>
    </div>
    <div class="col">
      <SectionTitle :icon="selectedCategory.icon">{{ selectedCategory.title }} Tools</SectionTitle>
      <div class="row">
        <div :class="selectedCategory.class">
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

import SectionTitle from '@/components/base/SectionTitle';
import VerticalPills from '@/components/base/VerticalPills';

export default {
  name: 'AdminTools',
  components: {
    SectionTitle,
    VerticalPills
  },
  data() {
    return {
      loading: false,
      categories: [
        { id: 'general', title: 'General', icon: 'fa-gear', class: 'col-12' },
        { id: 'asset', title: 'Asset', icon: 'fa-buildings', class: 'col-12' },
        { id: 'websocket', title: 'Web Socket', icon: 'fa-plug-circle-bolt', class: 'col-12' }
      ]
    };
  },
  computed: {
    ...mapGetters({
      loadingAction: 'asset/loadingAction'
    }),
    selectedCategory() {
      const id = this.$route.name?.startsWith('admin-tools-') ? this.$route.name.replace('admin-tools-', '') : 'general';
      return this.categories.find(c => c.id === id);
    }
  },
  methods: {
    onSelectCategory(item) {
      this.$router.push({ name: `admin-tools-${item.id}` });
    }
  }
};
</script>
